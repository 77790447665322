import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppStateModel } from '../../store/app-state.model';
import * as clientActions from './client.actions';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { globalLoading } from '../../store/actions/ui.actions';
import { UserService } from '../../user/user.service';
import { UserModel } from '../../user/user.model';
import { populateClientProjectForms, populateClientProjects, populateClientsList } from './client.actions';
import { ProjectService } from '../../project/project.service';
import { FormModel } from '../../form/form.model';


@Injectable()
export class ClientEffects {

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    private userService: UserService,
    private projectsService: ProjectService
  ) {
  }

  getClientsPerAssignment = createEffect(() => this.actions.pipe(
    ofType(clientActions.getClientsPerAssignment),
    switchMap(() => {
        return this.projectsService.customerUsersPerAssignment().pipe(
          mergeMap((res: UserModel[]) => {
            return [
              (globalLoading({ loading: false })),
              (populateClientsList(res))
            ];
          }),
          catchError((err) => {
            return [ (globalLoading({ loading: false })) ];
          })
        );
      }
    )
  ));

  getClientProjectsPerCustomerAssignment = createEffect(() => this.actions.pipe(
    ofType(clientActions.getClientProjectsPerCustomerAssignment),
    switchMap(({ id, identifier }) => {
        return this.projectsService.projectsPerCustomerAssignment(id, identifier).pipe(
          mergeMap((res) => {
            return [
              (globalLoading({ loading: false })),
              (populateClientProjects(res))
            ];
          }),
          catchError((err) => {
            return [ (globalLoading({ loading: false })) ];
          })
        );
      }
    )
  ));

  getRecordFormsPerProjectAssignment = createEffect(() => this.actions.pipe(
    ofType(clientActions.getClientProjectFormsPerAssignment),
    switchMap(({ projectId, datasetId }) => {
        return this.projectsService.recordFormsPerProjectAssignment(projectId, datasetId).pipe(
          mergeMap((res: Partial<FormModel>[]) => {
            return [
              (globalLoading({ loading: false })),
              (populateClientProjectForms(res))
            ];
          }),
          catchError((err) => {
            return [ (globalLoading({ loading: false })) ];
          })
        );
      }
    )
  ));

}

