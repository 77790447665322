import { EnvService } from './env.service';

export const EnvServiceFactory = () => {
  const env = new EnvService();

  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['_env'] || {};

  // properties from env.js overwrite defaults from the EnvService.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      env[key] = window['_env'][key];
    }
  }

  return env;
};

export const EnvServiceProvider = {
  provide: EnvService,
  useFactory: EnvServiceFactory,
  deps: [],
};
