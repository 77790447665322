import { appStateForm, FormStateModel } from './form-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as formActions from './form.actions';

const reducer = createReducer(appStateForm.form,
  on(formActions.setCurrentForm, (state, { form }) => ({ ...state, current: form })),
  on(formActions.setFormList, (state, { forms }) => ({ ...state, list: forms })),
  on(formActions.resetCurrentForm, (state) => {
    return {
      ...state, current: {
        id: null,
        projectId: null,
        assignmentId: null,
        organizationId: null,
        body: {
          pages: [],
          header: {},
          footer: {}
        },
        name: '',
        description: '',
        userIdCreated: null,
        userIdUpdated: null,
        shared: [],
        datasets: [],
        datasetId: null,
        questions: [],
        questionsPopulated: {},
        typeForm: 1,
        type: null,
        projectType: null,
        settings: {
          cardSettings: {
            cardBackgroundColor: '',
            cardButtonBackgroundColor: '',
            cardButtonForegroundColor: '',
            cardButtonOnHoverBackgroundColor: '',
            cardButtonOnHoverForegroundColor: '',
            cardForegroundPrimaryColor: '',
            cardForegroundSecondaryColor: '',
          }
        },
        createdAt: '',
        updatedAt: ''
      }
    };
  }),
  on(formActions.toggleModal, (state, { toggle }) => ({ ...state, modal: { ...state.modal, toggle: toggle } })),
  on(formActions.updateModalData, (state, { title, description }) => ({
    ...state,
    modal: { ...state.modal, title, description }
  }))
);

export function formReducer(state: FormStateModel, action: Action): FormStateModel {
  return reducer(state, action);
}
