import { appStateOrganization, OrganizationState } from './organization.state';
import * as organizationActions from './organization.actions';
import { Action, createReducer, on } from '@ngrx/store';


const reducer = createReducer(appStateOrganization.organization,
    on(organizationActions.updateCurrentOrganization, (state, { organization }) => ({ ...state, current: organization })),
    on(organizationActions.resetCurrentOrganization, (state) => ({
        ...state,
        current: {
            id: null,
            name: '',
            subDomain: '',
            company: {
                country: '',
                typeOfBusiness: null,
                email: '',
                phone: '',
            },
            contactPersonId: null,
            logoURL: '',
            createdAt: '',
            updatedAt: '',
            userIdCreated: null,
            userIdUpdated: null,
            statusId: null,
            securityGroupDetails: []
        }
    })),
);

export function organizationReducer(state: OrganizationState, action: Action): OrganizationState {
    return reducer(state, action);
}
