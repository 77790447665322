import { Injectable } from '@angular/core';
import { EnvService } from '../../environment-integration/env.service';

@Injectable()
export class AppConfig {
  config = {
    apis: {
      form: 'Form',
      user: 'User',
      record: 'Record',
      organization: 'Organization',
      datasetView: 'DatasetView',
      project: 'Project',
      group: 'SecurityGroup',
      assignment: 'Assignment'
    },
    filesPrefix: 'Meta-Forms-File',
    dateTimeFormatToISO: 'YYYY-MM-DD HH:mm:SSS',
    dateTimeFormatToUTC: true,
    utcFormat: 'YYYY-MM-DDTHH:mm:ss.sssZ'
  };

    constructor(
        private env: EnvService
    ) {
    }

    buildUri(entity): string {
        const base = `${ this.env.protocol }://${ this.env.domain }/api`;
        return `${ base }/${ this.config.apis[entity] }`;
    }

}
