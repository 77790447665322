import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UserModule } from './user/user.module';
import {PagesModule} from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { MfStoreModule } from './store/mf-store.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import {OrganizationModule} from './organization/organization.module';
import { EnvironmentIntegrationModule } from './environment-integration/environment-integration.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    MfStoreModule,
    UserModule,
    OrganizationModule,
    CoreModule.forRoot(),
    EnvironmentIntegrationModule,
    PagesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon('google-home-assistant', sanitizer.bypassSecurityTrustResourceUrl('../assets/images/home-assistant.svg'));
    this.iconRegistry.addSvgIcon('meta-forms-logo-checkbox', sanitizer.bypassSecurityTrustResourceUrl('../assets/images/metaforms-logo-checkbox.svg'));
    this.iconRegistry.registerFontClassAlias('mf-user', 'icon-mf-user');
    this.iconRegistry.registerFontClassAlias('mf-howto', 'icon-mf-howto');
  }
}
