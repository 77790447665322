import { UiStateModel } from '../models/ui-state.model';
import { appState } from '../app-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as uiActions from '../actions/ui.actions';

const reducer = createReducer(appState.ui,
  on(uiActions.loading, (state, { loading }) => ({ ...state, loading })),
  on(uiActions.globalLoading, (state, { loading }) => ({ ...state, globalLoading: loading })),
  on(uiActions.toggleSidebar, (state, { sidebar }) => ({ ...state, sidebar })),
  on(uiActions.toggleFooter, (state, { footer }) => ({ ...state, footer })),
  on(uiActions.toggleHeader, (state, { header }) => ({ ...state, header })),
  on(uiActions.setRedirectUrl, (state, { url }) => ({ ...state, redirectUrl: url })),
  on(uiActions.messagePopup, (state, { message }) => ({ ...state, message })),
  on(uiActions.errorPopup, (state, { error }) => ({ ...state, error })),
);

export function uiReducer(state: UiStateModel, action: Action): UiStateModel {
  return reducer(state, action);
}
