import { appStateRecord, RecordState } from './record.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as recordActions from './record.actions';

const reducer = createReducer(appStateRecord.record,
    on(recordActions.updateRecordList, (state, { records }) => ({ ...state, list: records })),
    on(recordActions.populateListPageTitle, (state, { title }) => ({ ...state, listPageTitle: title })),
    on(recordActions.populateRecordPermissions, (state, { permissions }) => ({ ...state, permissions })),
    on(recordActions.resetRecordList, (state) => ({ ...state, list: [] })),
    on(recordActions.updateRecordsCount, (state, { count }) => {
        return ({ ...state, count });
    }),
    on(recordActions.resetRecordsCount, (state) => ({ ...state, count: {} })),
    on(recordActions.updateCurrentRecord, (state, { record }) => ({ ...state, current: record })),
);

export function recordReducer(state: RecordState, action: Action): RecordState {
    return reducer(state, action);
}
