import { createAction, props } from '@ngrx/store';
import { UserModel } from '../../user/user.model';
import { ProjectModel } from '../../store/models/project.model';
import { FormModel } from '../../form/form.model';

export enum ActionTypes {
  GET_CLIENTS_PER_ASSIGNMENT = '[Client] Get clients per assignment',
  POPULATE_CLIENTS_LIST = '[Client] Populate Clients',
  SELECT_CLIENT = '[Client] Select client',
  RESET_SELECTED_CLIENT = '[Client] Reset selected client',
  GET_CLIENT_PROJECTS_PER_CUSTOMER_ASSIGNMENT = '[Client] Get Client Projects Per Customer Assignment',
  POPULATE_CLIENT_PROJECTS = '[Client] Populate Client Projects',
  RESET_CLIENT_PROJECTS = '[Client] Reset Client Projects',
  GET_CLIENT_PROJECT_FORMS_PER_ASSIGNMENT = '[Client] Get Client Project forms per assignment',
  RESET_CLIENT_PROJECT_FORMS = '[Client] Reset Client Project forms',
  POPULATE_CLIENT_PROJECT_FORMS = '[Client] Populate Client Project forms',

}

export const getClientsPerAssignment = createAction(ActionTypes.GET_CLIENTS_PER_ASSIGNMENT);
export const populateClientsList = createAction(ActionTypes.POPULATE_CLIENTS_LIST, (list: UserModel[]) => ({ list }));
export const selectClient = createAction(ActionTypes.SELECT_CLIENT, (client: UserModel) => ({ client }));
export const resetSelectedClient = createAction(ActionTypes.RESET_SELECTED_CLIENT);
export const getClientProjectsPerCustomerAssignment = createAction(ActionTypes.GET_CLIENT_PROJECTS_PER_CUSTOMER_ASSIGNMENT,
  props<{ id: number, identifier: string }>());
export const populateClientProjects = createAction(ActionTypes.POPULATE_CLIENT_PROJECTS, (projects: Partial<ProjectModel>[]) => ({ projects }));
export const resetClientProjects = createAction(ActionTypes.RESET_CLIENT_PROJECTS);
export const getClientProjectFormsPerAssignment = createAction(ActionTypes.GET_CLIENT_PROJECT_FORMS_PER_ASSIGNMENT, props<{ projectId: number, datasetId: number }>());
export const resetClientProjectsForms = createAction(ActionTypes.RESET_CLIENT_PROJECT_FORMS);
export const populateClientProjectForms = createAction(ActionTypes.POPULATE_CLIENT_PROJECT_FORMS, (forms: Partial<FormModel>[]) => ({ forms }));

