import {Component, Input} from '@angular/core';
import {StatusesModel} from './statuses.model';

@Component({
  selector: 'mfn-status-chips',
  templateUrl: './status-chips.component.html',
  styleUrls: ['./status-chips.component.scss']
})
export class StatusChipsComponent {

  @Input() statuses: StatusesModel = {};

  @Input() maxWidth = '180px';

  @Input() stacked = false;

  @Input() noStatusPlaceholder = 'No status';

  constructor() { }

}
