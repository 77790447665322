import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppAuthNService } from '../../user/app-auth-n.service';

import { Router } from '@angular/router';

@Injectable()
export class MfnHttp {

    constructor(
        private http: HttpClient,
        private appAuth: AppAuthNService,
        private router: Router,
    ) {
    }

    createAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
        const token = this.appAuth.getAuthorizationHeaderValue();
        return headers.append('Authorization', token);
    }

    get(url: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http.get<any>(url, {
            headers
        }).pipe(catchError(this.handleError));
    }

    post(url: string, data: any, options = { headers: new HttpHeaders() }): Observable<any> {
        options.headers = this.createAuthorizationHeader(options.headers);
        return this.http.post<any>(url, data, options).pipe(catchError(this.handleError));
    }

    put(url: string, data: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http.put<any>(url, data, {
            headers
        }).pipe(catchError(this.handleError));
    }

    delete(url: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = this.createAuthorizationHeader(headers);
        return this.http.delete(url, {
            headers
        }).pipe(catchError(this.handleError));
    }

    handleError = (res) => {
        const message = res.error && res.error.message ? res.error.message : 'There is an error';
        if (res.error.error === 'User is accessing organization that has no permission') {
            this.router.navigate([ '/wrong-organization' ]);
        }
        return observableThrowError(new Error(message));
    };

}
