import { UserModel } from '../user.model';

export interface UserState {
  profile: UserModel;
  errors: string[];
}

export interface AppStateUser {
  user: UserState;
}

export const appStateUser: AppStateUser = {
  user: {
    profile: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      firstName: '',
      lastName: '',
      position: '',
      isUserActive: false,
      avatarURL: '',
      securityGroup: [],
      subscriptionlevel: '',
      createdAt: '',
      updatedAt: '',
      userIdCreated: null,
      userIdUpdated: null,
      country: '',
      address: '',
      statusId: null,
      amr: [],
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: '',
      customFields: []

    },
    errors: []
  }
};
