import { Component, OnInit } from '@angular/core';
import { AppAuthNService } from '../../user/app-auth-n.service';

@Component({
    templateUrl: './auth-callback.component.html',
    styleUrls: [ './auth-callback.component.scss' ]
})
export class AuthCallbackComponent implements OnInit {

    constructor(private appAuth: AppAuthNService) {
    }

    ngOnInit(): void {
        this.appAuth.completeAuthentication();
    }

}
