export enum ActionTypes {
  GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION',
  UPDATE_CURRENT_ORGANIZATION = 'UPDATE_CURRENT_ORGANIZATION',
  RESET_CURRENT_ORGANIZATION = 'RESET_CURRENT_ORGANIZATION',
  GET_ANONYMOUS_USER_ORGANIZATION = 'GET_ANONYMOUS_USER_ORGANIZATION'
}

import { createAction, props } from '@ngrx/store';
import { OrganizationModel } from '../organization.model';

export const getUserOrganization = createAction(ActionTypes.GET_USER_ORGANIZATION);
export const updateCurrentOrganization =
  createAction(ActionTypes.UPDATE_CURRENT_ORGANIZATION, props<{ organization: OrganizationModel }>());
export const resetCurrentOrganization = createAction(ActionTypes.RESET_CURRENT_ORGANIZATION);
export const getAnonymousUserOrganization = createAction(ActionTypes.GET_ANONYMOUS_USER_ORGANIZATION,  props<{ anonymousURL: string }>());
