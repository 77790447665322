import {OrganizationModel} from '../organization.model';

export interface OrganizationState {
  current: OrganizationModel;
}
export interface AppStateOrganization {
  organization: OrganizationState;
}
export const appStateOrganization: AppStateOrganization = {
  organization: {
    current: {
      id: null,
      name: '',
      subDomain: '',
      company: {
        country: '',
        typeOfBusiness: null,
        email: '',
        phone: '',
      },
      contactPersonId: null,
      logoURL: '',
      createdAt: '',
      updatedAt: '',
      userIdCreated: null,
      userIdUpdated: null,
      statusId: null,
      securityGroupDetails: []
    }
  }
};
