import { createAction, props } from '@ngrx/store';
import { FormModel } from '../form.model';

export enum ActionTypes {
  AUTOSAVE = '[Form] Autosave',
  TOGGLE_MODAL = '[Form] Toggle Modal',
  UPDATE_MODAL_DATA = '[Form] Update Modal Data',
  SET_CURRENT_FORM = '[Form] Set current form',
  RESET_CURRENT_FORM = '[Form] Reset current form',
  GET_ASSIGNED_FORMS = '[Form] Get assigned forms',
  SET_FORM_LIST = '[Form] Set form list',
  GET_FORMS_BY_RECORD = '[Form] Get Forms By Record ID'
}

export const getAssignedForms = createAction(ActionTypes.GET_ASSIGNED_FORMS);
export const setFormList = createAction(ActionTypes.SET_FORM_LIST, (forms: FormModel[]) => ({ forms }));
export const setCurrentForm = createAction(ActionTypes.SET_CURRENT_FORM, (form: FormModel) => ({ form }));
export const resetCurrentForm = createAction(ActionTypes.RESET_CURRENT_FORM);
export const autosave = createAction(ActionTypes.AUTOSAVE, (autosave: boolean) => ({ autosave }));
export const toggleModal = createAction(ActionTypes.TOGGLE_MODAL, (toggle: boolean) => ({ toggle }));
export const updateModalData = createAction(ActionTypes.UPDATE_MODAL_DATA, (title: string, description: string) => ({
  title,
  description
}));
export const getFormsByRecordId = createAction(ActionTypes.GET_FORMS_BY_RECORD, (recordId: number, assignmentId: number) => ({
  recordId,
  assignmentId
}));
