import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';

export const toggleInOut = trigger('toggleInOut', [
  state('open', style({
    width: '250px'
  })),
  state('close', style({
    width: 0,
  })),
  transition('open => close', animate('.2s', keyframes([
    style({  width: '250px' }),
    style({  width: '230px' }),
    style({  width: '20px' }),
    style({  width: 0 })
  ]))),
  transition('close => open', animate('.2s', keyframes([
    style({  width: 0 }),
    style({  width: '200px' }),
    style({  width: '230px' }),
    style({  width: '250px' })
  ])))
]);
