import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserService} from './user.service';
import { IsLoggedGuard } from './is-logged.guard';
import {AppAuthNService} from './app-auth-n.service';
import {UserRoutingModule} from './user-routing.module';
import { UserDetailsComponent } from './user-details/user-details.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule
  ],
  declarations: [UserDetailsComponent, UserDetailsComponent],
  providers: [
    UserService,
    IsLoggedGuard,
    AppAuthNService
  ]
})
export class UserModule { }
