import { Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { OrganizationModel } from '../../organization/organization.model';
import { AppStateModel, getState } from '../../store/app-state.model';
import { EnvService } from '../../environment-integration/env.service';

@Component({
  templateUrl: './wrong-organization-domain.component.html',
  styleUrls: [ './wrong-organization-domain.component.scss' ]
})
export class WrongOrganizationDomainComponent implements OnInit, OnDestroy {

  organization: Observable<OrganizationModel>;
  private subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppStateModel>,
    private env: EnvService
  ) {
  }

  ngOnInit(): void {
    this.organization = this.store.pipe(select(state => state.organization.current));
    this.subscription.add(
      this.organization.subscribe(organization => {
        if (organization.subDomain && organization.subDomain.trim() !== '') {
          this.redirect();
        }

      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  redirect(): void {
    const organizationSubDomain = getState(this.store).organization.current.subDomain;
    window.location.href = `${ this.env.protocol }://${ organizationSubDomain }.${ this.env.mainDomain }`;
  }

  setupOrganization($event): void {
    $event.preventDefault();
    window.location.href = `${ this.env.protocol }://manager.${ this.env.mainDomain }`;
  }
}
