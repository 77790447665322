import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MfnHttp } from './http/http-client.service';
import { AppConfig } from './config/app.config';
import { HttpClientModule } from '@angular/common/http';
import { ProjectService } from '../project/project.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    exports: [ CommonModule ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                AppConfig,
                MfnHttp,
                ProjectService
            ]
        };
    }
}
