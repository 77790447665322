import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { WrongOrganizationDomainComponent } from './wrong-organization-domain/wrong-organization-domain.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

const declarations = [
    AuthCallbackComponent,
    WrongOrganizationDomainComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsOfUseComponent
];

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PagesRoutingModule
    ],
    declarations,
    exports: [
        ...declarations
    ]
})
export class PagesModule {
}
