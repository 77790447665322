import { Injectable } from '@angular/core';
import { MfnHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { map } from 'rxjs/operators';

@Injectable()
export class FormService {

    constructor(
        private http: MfnHttp,
        private config: AppConfig
    ) {
    }

    getAssignedForms() {
        return this.http.get(this.config.buildUri('form') + '/AssignedFormsPerUser');
    }

    formsByRecordId(recordId: number) {
        return this.http.get(this.config.buildUri('form') + '/FormsByRecordId?recordId=' + recordId);
    }

    getUsersByGroupId(groupId: number) {
        return this.http.get(this.config.buildUri('group') + '/SingleSecurityGroupWithUsers?securityGroupId=' + groupId).pipe(
            map((response) => response.usersDetails)
        );
    }

}
