import { appStateUser, UserState } from './user.state';
import * as userActions from './user.actions';
import { Action, createReducer, on } from '@ngrx/store';

const reducer = createReducer(appStateUser.user,
  on(userActions.resetUser, (state, {}) => {
    return {
      profile: {
        userId: null,
        organizationId: null,
        sub: '',
        email: '',
        family_name: '',
        given_name: '',
        userName: '',
        firstName: '',
        lastName: '',
        position: '',
        isUserActive: false,
        avatarURL: '',
        securityGroup: [],
        subscriptionlevel: '',
        createdAt: '',
        updatedAt: '',
        userIdCreated: null,
        userIdUpdated: null,
        country: '',
        address: '',
        statusId: null,
        amr: [],
        isPending: false,
        pinCode: null,
        phoneNumber: null,
        customerIdentifier: '',
        customFields: []
      },
      errors: []
    };
  }),
  on(userActions.updateUserProfile, (state, { user }) => ({ ...state, profile: user })),
  on(userActions.updateUserField, (state, { value, field }) => ({ ...state, profile: { ...state.profile, [field]: value } })),
  on(userActions.updateCurrentUserCustomFields, (state, { value, field }) => {
    const currentCustomFields = [ ...state.profile.customFields ];
    const customField = currentCustomFields.find(el => el.keyId === field);
    if (customField) {
      customField.value = value;
    }
    return {
      ...state, profile: { ...state.profile, customFields: [ ...currentCustomFields ] }
    };
  }),
);

export function userReducer(state: UserState, action: Action): UserState {
  return reducer(state, action);
}
