import { CardSettingsDTO } from '../shared/card2/card.model';

export enum FormType {
  DataCapture = 1,
  Survey = 2,
  Report = 3
}

export enum ProjectTypeEnum {
  GeneralPurpose = 1,
  Dossier = 2
}

export interface FormModel {
  id: number;
  projectId: number;
  projectName?: string;
  assignmentId: number;
  formName?: string;
  organizationId: number;
  body: {
    pages: any[];
    header: any;
    footer: any;
  };
  name: string;
  description: string;
  userIdCreated: number;
  userIdUpdated: number;
  shared: string[];
  datasets: any[];
  datasetId: number;
  questions: any[];
  questionsPopulated: any;
  typeForm: FormType;
  type: FormType;
  projectType: ProjectTypeEnum;
  settings: {
    cardSettings?: CardSettingsDTO
  };
  createdAt: string;
  updatedAt: string;
  recordCount?: number;
  assignmentUserIds?: number[];
}
