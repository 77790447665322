import { Component, Input } from '@angular/core';

@Component({
    selector: 'mfn-cards-list',
    templateUrl: './cards-list.component.html',
    styleUrls: [ './cards-list.component.scss' ]
})
export class MfnCardsListComponent {

    @Input() cards: any[];
    @Input() cardsType = '';

}
