import { uiReducer } from './ui.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { AppStateModel } from '../app-state.model';
import { formReducer } from '../../form/store/form.reducer';
import { userReducer } from '../../user/store/user.reducer';
import { recordReducer } from '../../record/store/record.reducers';
import { organizationReducer } from '../../organization/store/organization.reducer';
import { projectReducer } from './project.reducer';
import { clientReducer } from '../../client/store/client.reducer';

export const reducers: ActionReducerMap<AppStateModel> = {
  ui: uiReducer,
  form: formReducer,
  user: userReducer,
  record: recordReducer,
  organization: organizationReducer,
  project: projectReducer,
  client: clientReducer
};
