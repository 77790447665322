import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgPipesModule } from 'ngx-pipes';


import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { StatusChipsComponent } from './status-chips/status-chips.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MfnModalComponent } from './mfn-modal/mfn-modal.component';
import { MfnCardComponent } from './card2/card.component';
import { MfnCardsListComponent } from './cards-list/cards-list.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

import { MaterialModule } from './material/material.module';
import { MfnGridViewComponent } from './mf-grid/mf-grid.component';
import { MfmCardComponent } from './card/card.component';

const declarations = [
  UnderConstructionComponent,
  StatusChipsComponent,
  MfnModalComponent,
  MfmCardComponent,
  MfnCardComponent,
  MfnCardsListComponent,
  ConfirmationDialogComponent,
  UnderConstructionComponent,
  StatusChipsComponent,
  MfnGridViewComponent,
  ConfirmationDialogComponent,
];
const imports = [
  ReactiveFormsModule,
  FormsModule,
  NgPipesModule,
  HttpClientModule,
  MaterialModule
];

const forExports = [
  ...declarations,
  ...imports,
];

@NgModule({
  imports: [
    CommonModule,
    ...imports
  ],
  declarations,
  exports: forExports,
  entryComponents: [ ...declarations ]
})
export class SharedModule {
}
