import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userActions from './user.actions';
import { UserService } from '../user.service';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { errorPopup, loading, messagePopup } from '../../store/actions/ui.actions';

@Injectable()
export class UserEffects {

  constructor(
    private actions: Actions,
    private userService: UserService
  ) {
  }

  getUserBySub = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getUserBySub),
      switchMap(({ profile }) => this.userService.getUserBySub(profile.sub).pipe(
        mergeMap((res) => {
          const user = { ...profile, ...res };
          return [
            (userActions.updateUserProfile({ user }))
          ];
        }),
        catchError(err => {
          return [];
        })
      ))
    );
  });

  updateUser = createEffect(() => this.actions.pipe(
    ofType(userActions.updateUser),
    switchMap(({ user }) => {
      return this.userService.updateUser(user).pipe(
        mergeMap((res) => {
          const profile = { ...user, ...res };
          return [
            (messagePopup({ message: 'User Updated.' })),
            (userActions.updateUserProfile({ user: profile })),
            (loading({ loading: false })),
          ];
        }),
        catchError(err => {
          return [
            (errorPopup({ error: 'There is a problem with updating user details' })),
            (loading({ loading: false })),
          ];
        })
      );
    })
  ));
  uploadUserAvatar = createEffect(() => this.actions.pipe(
    ofType(userActions.uploadUserAvatar),
    switchMap(({ formData }) => {
      return this.userService.uploadUserAvatar(formData).pipe(
        mergeMap((res: string) => {
          return [
            (loading({ loading: false })),
            (messagePopup({ message: 'Image uploaded.' })),
            (userActions.updateUserField({ field: 'avatarURL', value: res }))
          ];
        }),
        catchError(err => {
          return [
            (errorPopup({ error: 'There is a problem with Image uploading' })),
            (loading({ loading: false }))
          ];
        }));
    })
  ));
}
