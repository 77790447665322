import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { debounceTime, take } from 'rxjs/operators';

import { ICustomFields, UserModel } from '../user.model';
import { AppStateModel, getState } from '../../store/app-state.model';
import { updateCurrentUserCustomFields, updateUser, updateUserField, uploadUserAvatar } from '../store/user.actions';
import { SecurityGroupType } from '../../organization/organization.model';
import { loading } from '../../store/actions/ui.actions';


@Component({
  templateUrl: './user-details.component.html',
  styleUrls: [ './user-details.component.scss' ]
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  user: Observable<UserModel>;
  form: FormGroup;
  customerGroupName: string;
  loading$: Observable<boolean>;

  get customFields(): FormGroup {
    return this.form.get('customFields') as FormGroup;
  }

  private subscription: Subscription = new Subscription();

  constructor(private store: Store<AppStateModel>,
              private fb: FormBuilder,
              private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.user = this.store.select(state => state.user.profile);
    this.loading$ = this.store.select(state => state.ui.loading);
    this.form = this.fb.group({
      firstName: [ '', Validators.required ],
      lastName: [ '', Validators.required ],
      department: [ '' ],
      position: [ '' ],
      customerIdentifier: [ '' ],
      pinCode: [ '', Validators.maxLength(16) ],
      phoneNumber: [ '' ],
      customFields: this.fb.group({})
    });

    this.synchronizeWithStore();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateUser(): void {
    if (this.form.invalid) {
      return;
    }
    this.store.dispatch(loading({ loading: true }));
    const user = getState(this.store).user.profile;
    this.store.dispatch(updateUser({ user }));
  }

  private synchronizeWithStore(): void {
    this.subscription.add(this.user.subscribe(user => {
      this.form.patchValue(user, { emitEvent: false });
      if (user.customFields.length) {
        user.customFields.forEach((field: ICustomFields) => {
          this.customFields.addControl(field.keyId, new FormControl(field.value), { emitEvent: false });
          if (field.isReadonly) {
            this.customFields.get(field.keyId).disable({ emitEvent: false });
          } else {
            this.subscription.add(
              this.customFields.get(field.keyId).valueChanges.subscribe((value) => {
                this.store.dispatch(updateCurrentUserCustomFields({ field: field.keyId, value }));
              })
            );
          }
        });
      }
    }));
    this.subscription.add(this.form.get('firstName').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'firstName', value }));
    }));

    this.subscription.add(this.form.get('lastName').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'lastName', value }));
    }));

    this.subscription.add(this.form.get('department').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'department', value }));
    }));
    this.subscription.add(this.form.get('position').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'position', value }));
    }));
    this.subscription.add(this.form.get('customerIdentifier').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'customerIdentifier', value }));
    }));

    this.subscription.add(this.form.get('pinCode').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'pinCode', value }));
    }));

    this.subscription.add(this.form.get('phoneNumber').valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.store.dispatch(updateUserField({ field: 'phoneNumber', value }));
    }));

    this.subscription.add(
      this.store.select(state => state.organization.current.securityGroupDetails).pipe(
        take(2)
      ).subscribe(securityGroupDetails => {
        const customerGroup = securityGroupDetails.find(group => group.groupType === SecurityGroupType.Customer); // Customer
        this.customerGroupName = customerGroup.value || '';
      })
    );
  }

  fileChange(event): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      formData.append('userId', getState(this.store).user.profile.userId + '');
      formData.append('file', file);
      this.store.dispatch(loading({ loading: true }));
      this.store.dispatch(uploadUserAvatar({ formData }));
    }
  }

  getImageUrl(imageUrl: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${ imageUrl })`);
  }

}
