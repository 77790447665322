import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UserDetailsComponent} from './user-details/user-details.component';
const routes = [
  {
    path: '', children: [
      { path: '', redirectTo: 'details', pathMatch: 'prefix'},
      { path: 'details', component: UserDetailsComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule { }
