import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { RecordService } from '../record.service';
import { mergeMap, catchError, switchMap, tap } from 'rxjs/operators';
import { RecordModel } from '../record.model';
import * as recordActions from './record.actions';
import { globalLoading } from '../../store/actions/ui.actions';

@Injectable()
export class RecordEffects {
    constructor(
        private actions: Actions,
        private recordService: RecordService,
    ) {
    }

    deleteRecord = createEffect(() => this.actions.pipe(
        ofType(recordActions.deleteRecord),
        switchMap(({ recordId, assignmentId, projectId }) => this.recordService.deleteRecord(recordId).pipe(
            mergeMap(res => [
                (recordActions.getRecordsByProjectId({ projectId: projectId, assignmentId: assignmentId })),
                (globalLoading({ loading: false }))
            ]),
            catchError(err => {
                return [ (globalLoading({ loading: false })) ];
            })))
    ));

    createRecord = createEffect(() => this.actions.pipe(
        ofType(recordActions.createRecord),
        switchMap(({ data, assignmentId, projectId }) => this.recordService.createRecord(data, assignmentId).pipe(
            mergeMap((res: RecordModel) =>
                [
                    (globalLoading({ loading: false })),
                    (recordActions.setRecordId({ recordId: res.recordId })),
                    (recordActions.updateDataset({ dataset: res.data })),
                    (recordActions.setStatuses({ statuses: res.statuses })),
                    (recordActions.messagePopup({ message: 'Record created' })),
                    (recordActions.getRecordsByProjectId({ projectId: projectId, assignmentId: assignmentId })),
                    (recordActions.updateCurrentRecord({ record: { ...res, isNew: true } }))
                ]
            ),
            catchError(err => {
                return [ (globalLoading({ loading: false })) ];
            })
        ))
    ));

    getRecordsByProjectId = createEffect(() => {
        const setStatus = (list) => {
            return list.map(record => {
                let status = 0;
                if (record.statuses) {
                    if (record.statuses.completion) {
                        switch (record.statuses.completion.title) {
                            case 'In Progress':
                                status = 1;
                                break;
                            case 'Completed':
                                status = 2;
                                break;
                            default:
                                status = 4;
                        }
                    }
                }
                return { ...record, status };
            });
        };
        return this.actions.pipe(
            ofType(recordActions.getRecordsByProjectId),
            switchMap(({ projectId, assignmentId }) => {
                    return this.recordService.getRecordsPerProject(projectId, assignmentId).pipe(
                        mergeMap(res => [
                            (recordActions.updateRecordList({ records: setStatus(res.navigatorRecordGridDTO) })),
                            (recordActions.populateListPageTitle({ title: res.title })),
                            (recordActions.populateRecordPermissions({ permissions: res.permissions })),
                            (globalLoading({ loading: false }))
                        ]),
                        catchError(err => {
                            return [ (globalLoading({ loading: false })) ];
                        }));
                }
            )
        );
    });

    getRecordsByProjectsIds = createEffect(() => this.actions.pipe(
        ofType(recordActions.getRecordsByProjectsIds),
        switchMap(({ projectIds }) => {
                return this.recordService.getRecordsPerProjectsCount(projectIds).pipe(
                    mergeMap(res => {
                            return [ (recordActions.updateRecordsCount({ count: res })) ];
                        }
                    ),
                    catchError(err => {
                        return [];
                    }));
            }
        )
    ));
}
