import { ProjectStateModel } from '../models/project.model';
import { appState } from '../app-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as projectActions from '../actions/project.actions';

const reducer = createReducer(appState.project,
    on(projectActions.setProject, (state, { project }) => ({ ...state, current: project })),
);

export function projectReducer(state: ProjectStateModel, action: Action): ProjectStateModel {
    return reducer(state, action);
}
