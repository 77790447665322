import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

import { AppStateModel } from '../../store/app-state.model';

@Component({
    templateUrl: './mfn-modal.component.html',
    styleUrls: [ './mfn-modal.component.scss' ]
})
export class MfnModalComponent implements OnInit, OnDestroy {

    public data: Observable<{ title: string, description: string, toggle: boolean }>;
    public subscription: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<MfnModalComponent>,
        private store: Store<AppStateModel>
    ) {
    }

    ngOnInit(): void {
        this.data = this.store.select(state => state.form.modal);
        this.subscription.add(this.store.select(state => state.form.modal.toggle)
            .subscribe(toggle => {
                if (!toggle) {
                    this.dialogRef.close();
                }
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
