import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppStateModel} from '../store/app-state.model';

import {AppAuthNService} from './app-auth-n.service';

@Injectable()
export class IsLoggedGuard implements CanActivate {

  constructor(
    private store: Store<AppStateModel>,
    private appAuth: AppAuthNService
  ) { }

  canActivate(): boolean {
    if (this.appAuth.isLoggedIn()) {
      return true;
    }
    this.appAuth.login();
    return false;
  }
}
