import { appStateClient, ClientStateModel } from './client-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as formActions from './client.actions';

const reducer = createReducer(appStateClient.client,
  on(formActions.populateClientsList, (state, { list }) => {
    return { ...state, list };
  }),
  on(formActions.selectClient, (state, { client }) => ({ ...state, current: client })),
  on(formActions.resetSelectedClient, (state) => {
    return {
      ...state,
      current: appStateClient.client.current
    };
  }),
  on(formActions.resetClientProjects, (state) => {
    return {
      ...state,
      projects: []
    };
  }),
  on(formActions.populateClientProjects, (state, { projects }) => {
    return {
      ...state,
      projects
    };
  }),
  on(formActions.populateClientProjectForms, (state, { forms }) => {
    return {
      ...state,
      forms
    };
  })
);

export function clientReducer(state: ClientStateModel, action: Action): ClientStateModel {
  return reducer(state, action);
}
