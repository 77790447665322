import { UserModel } from '../../user/user.model';
import { ProjectModel } from '../../store/models/project.model';
import { RecordModel } from '../../record/record.model';
import { FormModel } from '../../form/form.model';

export interface ClientStateModel {
  list: UserModel[];
  current: UserModel;
  projects: Partial<ProjectModel>[];
  records: RecordModel[];
  forms: Partial<FormModel>[];
}

export interface AppStateClient {
  client: ClientStateModel;
}

export const appStateClient: AppStateClient = {
  client: {
    list: [],
    projects: [],
    forms: [],
    records: [],
    current: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      firstName: '',
      lastName: '',
      position: '',
      isUserActive: false,
      avatarURL: '',
      securityGroup: [],
      subscriptionlevel: '',
      createdAt: '',
      updatedAt: '',
      userIdCreated: null,
      userIdUpdated: null,
      country: '',
      address: '',
      statusId: null,
      amr: [],
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: '',
      customFields: []
    }
  }
};
