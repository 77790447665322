import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';
import { EffectsModule } from '@ngrx/effects';

import { environment } from '../../environments/environment';
import { AppStateModel } from './app-state.model';
import { reducers } from './reducers';
import { FormEffects } from '../form/store/form.effects';
import { UserEffects } from '../user/store/user.effects';
import { RecordEffects } from '../record/store/record.effect';
import { RecordService } from '../record/record.service';
import { OrganizationEffects } from '../organization/store/organization.effects';
import { FormService } from '../form/form.service';
import { ProjectEffects } from './effects/project.effect';
import { ClientEffects } from '../client/store/client.effects';

export function localStorageSyncReducer(reducer: ActionReducer<AppStateModel>): ActionReducer<AppStateModel> {
    return localStorageSync({ keys: [ 'ui', 'user', 'organization' ], rehydrate: true })(reducer);
}

const metaReducers: MetaReducer<AppStateModel>[] = [ localStorageSyncReducer ];

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers, {
            metaReducers, runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([
            FormEffects,
            UserEffects,
            RecordEffects,
            OrganizationEffects,
            ProjectEffects,
            ClientEffects
        ])
    ],
    declarations: [],
    exports: [
        StoreModule
    ],
    providers: [ RecordService, FormService ]
})
export class MfStoreModule {
}
