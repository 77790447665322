import {
  AfterViewInit, ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import { GridConfigViewModel } from './grid-config-view.model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { expandCollapseDetails } from '../../animations';

@Component({
  selector: 'mfn-grid-view',
  templateUrl: './mf-grid.component.html',
  styleUrls: [ './mf-grid.component.scss' ],
  animations: [ expandCollapseDetails ]
})
export class MfnGridViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataSource: any[] = [];
  @Input() detailsData: any[] = [];
  @Input() recordId;
  @Input() detailsDataTemplate: TemplateRef<any>;
  @Input() config: GridConfigViewModel;
  @Input() shadowClasses = [ 'mat-elevation-z8' ];
  @Output() afterRowSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  pagingOptions: number[] = [ 5, 10, 20, 50 ];
  selectedRecord;
  visibleColumns: string[] = [];

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }


  ngOnInit(): void {
    this.visibleColumns = this.config.columns.filter(c => c.show).map(c => c.field);
    this.data = new MatTableDataSource(this.dataSource);
    this.data.sort = this.sort;
    this.data.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue) {
      if (this.data && this.data.data) {
        this.data.data = changes.dataSource.currentValue;
        this.selectedRecord = null;
        this.data.sort = this.sort;
      }
      if (this.data && this.data.hasOwnProperty('paginator')) {
        this.data.paginator.firstPage();
      }
    }
    setTimeout(() => {
      if (this.config.paging.paging) {
        this.setPagingOptions();
      }

      this.setDisplayedColumns();
    });
  }

  ngAfterViewInit(): void {
    if (this.config.paging.paging) {
      this.data.paginator = this.paginator;
      this.data.paginator.firstPage();
    }
  }

  selectRow(element: any): void {
    if (!this.recordId) {
      return;
    }
    if (this.selectedRecord) {
      if (this.selectedRecord[this.recordId] === element[this.recordId]) {
        this.selectedRecord = null;
        this.afterRowSelected.emit(null);
        return;
      }
    }
    this.selectedRecord = element;
    this.afterRowSelected.emit(element);
  }

  private setPagingOptions(): void {
    this.pagingOptions = this.config.paging ? this.config.paging.pageSizeOptions.split(',').map(p => +p) : [ 5, 10, 20, 50 ];
  }

  private setDisplayedColumns(): void {
    this.displayedColumns = this.config.columns.filter(c => c.show).map(c => c.field);
    this.cd.detectChanges();
  }

}
