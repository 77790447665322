import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfig } from '../core/config/app.config';
import { UserModel } from './user.model';
import { MfnHttp } from '../core/http/http-client.service';

@Injectable()
export class UserService {

  constructor(
    private config: AppConfig,
    private http: MfnHttp
  ) {
  }

  getUserBySub(sub: string): Observable<any> {
    return this.http.get(this.config.buildUri('user') + '/UserBySub/?sub=' + sub);
  }

  updateUser(user: UserModel): Observable<any> {
    return this.http.put(this.config.buildUri('user') + '/User', user);
  }

  uploadUserAvatar(formData: FormData): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = {
      headers,
      reportProgress: true
    };
    return this.http.post(this.config.buildUri('user') + '/UserFileUpload', formData, options);
  }

}

