import { RecordModel } from '../record.model';


export interface RecordState {
    list: RecordModel[];
    count: any;
    current: RecordModel;
    listPageTitle: string;
    permissions: any[];
}

export interface AppStateRecord {
    record: RecordState;
}

export const appStateRecord: AppStateRecord = {
    record: {
        list: [],
        count: {},
        current: {
            recordId: 0,
            fullName: '',
            projectRecordID: 0,
            title: '',
            createdAt: '',
            updatedAt: '',
            assignedTo: '',
            statuses: {},
            status: 0
        },
        listPageTitle: '',
        permissions: []
    }
};
