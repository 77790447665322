import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { AppStateModel } from '../../store/app-state.model';
import * as formActions from './form.actions';
import { FormService } from '../form.service';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { globalLoading } from '../../store/actions/ui.actions';


@Injectable()
export class FormEffects {
    constructor(
        private store: Store<AppStateModel>,
        private actions: Actions,
        private formService: FormService
    ) {
    }

    getAssignedForms = createEffect(() => this.actions.pipe(
        ofType(formActions.getAssignedForms),
        switchMap(() => {
                return this.formService.getAssignedForms().pipe(
                    mergeMap(res => {
                        return [
                            (globalLoading({ loading: false })),
                            (formActions.setFormList(res))
                        ];
                    }),
                    catchError(err => {
                        return [ (globalLoading({ loading: false })) ];
                    })
                );
            }
        )
    ));

    formsByRecordId = createEffect(() => this.actions.pipe(
        ofType(formActions.getFormsByRecordId),
        switchMap(({ recordId }) => {
            return this.formService.formsByRecordId(recordId).pipe(
                mergeMap(res => {
                    return [
                        (globalLoading({ loading: false })),
                        (formActions.setFormList(res))
                    ];
                }),
                catchError(err => {
                    return [ (globalLoading({ loading: false })) ];
                })
            );
        })
    ));

}

