export interface ProjectModel {
  name: string;
  id: number;
  datasetId: number;
  description: string;
  displayName?: string;
  forms: number[];
  formCount: number;
  recordTitleField: string;
  createdAt: Date;
  updatedAt: Date;
  userIdCreated: number;
  userIdUpdated: number;
}

export interface ProjectStateModel {
  current: ProjectModel;
}

export const projectState: ProjectStateModel = {
  current: {
    id: null,
    name: '',
    datasetId: null,
    description: '',
    displayName: '',
    forms: [],
    formCount: null,
    recordTitleField: '',
    createdAt: null,
    updatedAt: null,
    userIdCreated: null,
    userIdUpdated: null
  }
};
