import { Injectable } from '@angular/core';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ProjectService } from '../../project/project.service';
import * as projectActions from '../actions/project.actions';
import { globalLoading } from '../actions/ui.actions';

@Injectable()
export class ProjectEffects {
    constructor(
        private actions: Actions,
        private project: ProjectService
    ) {
    }

    getRecordsByProjectId = createEffect(() => this.actions.pipe(
        ofType(projectActions.getProjectById),
        switchMap(({ projectId }) => this.project.getProjectById(projectId).pipe(
            mergeMap(res => [ (projectActions.setProject({ project: res })) ]),
            catchError(err => [ (globalLoading({ loading: false })) ])
        ))
    ));
}
