import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const menuToggle = trigger('menuToggle', [
  state('open', style({
    left: '250px'
  })),
  state('close', style({
    left: 0
  })),
  transition('open => close', animate('.4s', keyframes([
    style({ opacity: 0, left: '250px' }),
    style({ opacity: 0, left: '200px' }),
    style({ opacity: .1, left: '20px' }),
    style({ opacity: 1, left: 0 })
  ]))),
  transition('close => open', animate('.4s', keyframes([
    style({ opacity: 0, left: 0 }),
    style({ opacity: 0, left: '100px' }),
    style({ opacity: .1, left: '230px' }),
    style({ opacity: 1, left: '250px' })
  ])))
]);
