import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';

/**
 * pages routes
 */
const routes = [ {
    path: '', children: [
        { path: 'privacy-policy', component: PrivacyPolicyComponent },
        { path: 'cookie-policy', component: CookiePolicyComponent },
        { path: 'terms-of-use', component: TermsOfUseComponent }
    ]
} ];

/**
 * This module is responsible only for pages routes
 */
@NgModule({
    imports: [ RouterModule.forChild(routes) ],
    exports: [ RouterModule ]
})
/**
 * App routing class
 */
export class PagesRoutingModule {
}
