import { take, skip } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { Store } from '@ngrx/store';
import { AppStateModel, getState } from '../store/app-state.model';
import { getUserBySub } from './store/user.actions';
import { setRedirectUrl } from '../store/actions/ui.actions';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { getUserOrganization, resetCurrentOrganization } from '../organization/store/organization.actions';
import { EnvService } from '../environment-integration/env.service';


export { User };


@Injectable()
export class AppAuthNService {

    userManager: UserManager;
    subscription: Subscription = new Subscription();

    private user: User = null;

    constructor(
        private store: Store<AppStateModel>,
        private router: Router,
        private env: EnvService
    ) {
        const settings: UserManagerSettings = {
            // authority: 'https://localhost:44361/', //for localtest
            authority: 'https://login.' + env.mainDomain + '/',
            client_id: 'MetaFormClient',
            // client_secret: environment.clientSecret,
            redirect_uri: window.location.origin + '/auth-callback',
            post_logout_redirect_uri: window.location.origin + '/',
            // these two will be done dynamically from the buttons clicked, but are
            // needed if you want to use the silent_renew
            response_type: 'code',
            scope: 'openid profile address metaformsnavigatorapi metaformsbrowserapi',
            // this will toggle if profile endpoint is used
            loadUserInfo: true,
            // silent renew will get a new access_token via an iframe
            // just prior to the old access_token expiring (60 seconds prior)
            silent_redirect_uri: window.location.origin + '/assets/silent.html',
            automaticSilentRenew: true,
            // will revoke (reference) access tokens at logout time
            revokeAccessTokenOnSignout: true,
            // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
            // wouldn't care about them or want them taking up space
            filterProtocolClaims: false
        };
        this.userManager = new UserManager(settings);
        this.userManager.getUser().then(user => {
            this.user = user;
        });
        this.userManager.events.addSilentRenewError(() => {
            this.login();
        });

        this.userManager.events.addUserLoaded(user => {
            this.user = user;
            sessionStorage.setItem('mfbToken', `${ this.user.token_type } ${ this.user.access_token }`);
        });

    }

    public getUser(): Promise<User> {
        return this.userManager.getUser().then(user => user);
    }

    public login(): Promise<void> {
        const href = window.location.href;
        const host = window.location.host;
        const hostLength = host.length;
        const startIndex = href.indexOf(host) + hostLength;
        const url = href.substr(startIndex);
        this.store.dispatch(setRedirectUrl({ url: url }));

        return this.userManager.signinRedirect();
    }

    completeAuthentication(): Promise<void> {
        return this.userManager.signinRedirectCallback().then(user => {
            this.user = user;

            const profile = this.store.select(s => s.user.profile);
            profile.pipe(skip(1), take(1),).subscribe(p => {
                if (p.organizationId) {
                    this.store.dispatch(getUserOrganization());
                }
            });

            sessionStorage.setItem('mfbToken', `${ this.user.token_type } ${ this.user.access_token }`);
            this.store.dispatch(getUserBySub({ profile: user.profile }));
            const redirectUrl = getState(this.store).ui.redirectUrl;
            this.store.dispatch(setRedirectUrl({ url: '' }));
            this.router.navigate([ redirectUrl ]);
        });
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<void> {
        this.store.dispatch(resetCurrentOrganization());
        sessionStorage.removeItem('mfbToken');
        return this.userManager.signoutRedirect();
    }

    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    getAuthorizationHeaderValue(): string {
        this.userManager.getUser().then(user => {
            this.user = user;
        });
        return `${ this.user.token_type } ${ this.user.access_token }`;
    }
}
