import {animate, state, style, transition, trigger} from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  state('open', style({
    marginLeft: '250px',
    width: 'calc(100% - 250px)'
  })),
  state('close', style({
    marginLeft: '15px',
    width: 'calc(100% - 15px)'
  })),
  transition('open <=> close', animate('.2s ease-in-out'))
]);
