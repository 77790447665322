import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  LOADING = 'LOADING',
  TOGGLE_HEADER = '[Ui] Toggle Header',
  TOGGLE_FOOTER = '[Ui] Toggle Footer',
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  GLOBAL_LOADING = 'GLOBAL_LOADING',
  ERROR_POPUP = 'ERROR_POPUP',
  SET_MSG_POPUP = 'SET_MSG_POPUP'
}

export const loading = createAction(ActionTypes.LOADING, props<{ loading: boolean }>());
export const globalLoading = createAction(ActionTypes.GLOBAL_LOADING, props<{ loading: boolean }>());
export const toggleSidebar = createAction(ActionTypes.TOGGLE_SIDEBAR, props<{ sidebar: string }>());
export const toggleHeader = createAction(ActionTypes.TOGGLE_HEADER, props<{ header: boolean }>());
export const toggleFooter = createAction(ActionTypes.TOGGLE_FOOTER, props<{ footer: boolean }>());
export const setRedirectUrl = createAction(ActionTypes.SET_REDIRECT_URL, props<{ url: string }>());
export const messagePopup = createAction(ActionTypes.SET_MSG_POPUP, props<{ message: string }>());
export const errorPopup = createAction(ActionTypes.ERROR_POPUP, props<{ error: string }>());

