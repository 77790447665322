import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import {IsLoggedGuard} from '../user/is-logged.guard';
import {AuthCallbackComponent} from '../pages/auth-callback/auth-callback.component';
import { WrongOrganizationDomainComponent } from '../pages/wrong-organization-domain/wrong-organization-domain.component';

/**
 * App routes
 */
const routes = [
  { path: '', redirectTo: '/dashboard/form/list', pathMatch: 'full' },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  {
    path: 'wrong-organization',
    component: WrongOrganizationDomainComponent
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    canActivate: [IsLoggedGuard]
  },
  {
    path: 'anonymous',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    data: {isAnonymous: true}
  },
  {
    path: 'export',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    data: {isExport: true}
  },
  {
    path: 'report',
    loadChildren: () => import('../layout/layout.module').then(m => m.LayoutModule),
    canActivate: [IsLoggedGuard]
  },
];

/**
 * This module is responsible only for application routes
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
/**
 * App routing class
 */
export class AppRoutingModule { }
