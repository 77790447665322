export class EnvService {
  // defaults are going to be overridden by env.js

  public production: false;
  public mainDomain: 'metaforms.app';
  public domain: 'navigator.metaforms.app';
  public clientId: 'JSDevClient';
  public clientSecret: 'jsdevclient;dorgfgitvzmoygksqjpm,';
  public protocol: 'https';
  public version: '#version';
  public environment: 'Develop';
}
