import { createAction, props } from '@ngrx/store';
import { ProjectModel } from '../models/project.model';

export enum ActionTypes {
    GET_BY_ID = '[Project] Get Project By ID',
    SET_CURRENT = '[Project] Set Current Project'
}

export const getProjectById = createAction(ActionTypes.GET_BY_ID, props<{ projectId: number }>());
export const setProject = createAction(ActionTypes.SET_CURRENT, props<{ project: ProjectModel }>());
