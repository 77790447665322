
export enum SecurityGroupType {
  AuthenticatedUsers = 1,
  Anonymous = 2,
  Agent = 3,
  Customer = 4
}

export interface SecurityGroup {
  key: number;
  value: string;
  groupType: SecurityGroupType;
}


export interface OrganizationModel {
  id: number;
  name: string;
  subDomain: string;
  company: {
    country: string;
    typeOfBusiness: number;
    email: string;
    phone: string;
  };
  contactPersonId: number;
  logoURL: string;
  createdAt: string;
  updatedAt: string;
  userIdCreated: number;
  userIdUpdated: number;
  statusId: number;
  securityGroupDetails: SecurityGroup[]
}
