import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppConfig } from '../core/config/app.config';
import { MfnHttp } from '../core/http/http-client.service';

@Injectable()
export class RecordService {

    constructor(
        private http: MfnHttp,
        private config: AppConfig
    ) {
    }

    getRecordsPerProject(projectId: number, assigmentId: number): Observable<any> {
        const url = `${ this.config.buildUri('record') }/RecordsPerProject?projectId=${ projectId }&assigmentId=${ assigmentId }`;
        return this.http.get(url);
    }

    getRecordsPerProjectsCount(projectIds: number[]): Observable<any> {
        return this.http.post(this.config.buildUri('record') + '/RecordsPerProjectsCount', projectIds);
    }

    deleteRecord(recordId: number): Observable<any> {
        return this.http.delete(`${ this.config.buildUri('record') }/Record?id=${ recordId }`);
    }

    createRecord(data, assignmentId): Observable<any> {
        const params = {
            assignmentId,
            data
        };
        return this.http.post(this.config.buildUri('record') + '/Record', params);
    }

}
