import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CardModel } from './card.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormType } from '../../form/form.model';

@Component({
  selector: 'mfn-card2',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss' ]
})
export class MfnCardComponent implements OnInit {

  @Input() card: CardModel<any>;
  @Input() buttonTemplate: TemplateRef<any>;
  @Input() footerInfoLeft: TemplateRef<any>;
  @Input() footerInfoRight: TemplateRef<any>;

  @Output() cardSelected: EventEmitter<CardModel<any>> = new EventEmitter<CardModel<any>>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  url = '../edit';
  cardTitleLength = 55;
  formTypesEnum = FormType;
  cardBtnStyles = {};
  cardStyle = {};
  cardPrimaryColor = {};
  cardSecondaryColor = {};

  constructor(
  ) {
  }

  ngOnInit(): void {
    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings && this.card.card.settings) {
      this.cardBtnStyles['background-color'] = this.card.card.settings.buttonBackgroundColor;
      /* eslint-disable-next-line */
      this.cardBtnStyles['color'] = this.card.card.settings.buttonForegroundColor;
      this.cardStyle['background-color'] = this.card.card.settings.backgroundColor;
      /* eslint-disable-next-line */
      this.cardPrimaryColor['color'] = this.card.card.settings.foregroundPrimaryColor;
      /* eslint-disable-next-line */
      this.cardSecondaryColor['color'] = this.card.card.settings.foregroundSecondaryColor;
    }
  }

  cardClick(card: CardModel<any>): void {
    // if (this.card.card.type === 'assignment') {
    //   this.store.dispatch(getAssignById({ id: card.original.id }));
    //   const dialogRef = this.dialog.open(AssignFormsComponent, {
    //     width: '980px',
    //     minWidth: 980,
    //     backdropClass: 'assign-form_backdrop',
    //     data: {
    //       mode: 'edit'
    //     }
    //   });
    //   dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
    //   });
    // } else {
    //   if (this.card.card.type === 'customerProject') {
    //     this.router.navigate([ `dashboard/project/edit/${ this.card.original.projectId }/data/forms` ]);
    //   } else {
    //     this.router.navigate([ this.url, card.original.id ], { relativeTo: this.activatedRoute });
    //   }
    // }
  }

  deleteCard(event, card: CardModel<any>): void {
    // event.stopImmediatePropagation();
    // const cardName = this.card.card.type === 'assignment' ? card.original.formName : card.card.name;
    // this.confirmDialog.openConfirmDialog('Do you want to remove ' + cardName + '?').subscribe(result => {
    //   if (result) {
    //     this.deleteElement(card);
    //   } else {
    //     this.trigger.closeMenu();
    //   }
    // });
  }

  deleteElement(card: CardModel<any>): void {
    // this.store.dispatch(this.deleteActions[this.card.card.type]({ id: card.original.id }));
  }

  mouseOver($event: MouseEvent): void {
    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings) {
      /* eslint-disable-next-line */
      $event.target['style'].backgroundColor = this.card.card.settings.buttonOnHoverBackgroundColor;
      /* eslint-disable-next-line */
      $event.target['style'].color = this.card.card.settings.buttonOnHoverForegroundColor;
    }
  }

  mouseLeave($event: MouseEvent): void {
    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings) {
      /* eslint-disable-next-line */
      $event.target['style'].backgroundColor = this.cardBtnStyles['background-color'];
      /* eslint-disable-next-line */
      $event.target['style'].color = this.cardBtnStyles['color'];
    }

  }

}
