import { Injectable } from '@angular/core';
import { MfnHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';
import { ProjectModel } from '../store/models/project.model';
import { FormModel } from '../form/form.model';

@Injectable()
export class ProjectService {
  constructor(
    private http: MfnHttp,
    private config: AppConfig
  ) {
  }

  getProjectById(projectId: number): Observable<ProjectModel> {
    return this.http.get(`${ this.config.buildUri('project') }/ProjectById?id=${ projectId }`);
  }

  getClientProjects(id: number, identifier: string): Observable<Partial<ProjectModel>[]> {
    return this.http.get(`${ this.config.buildUri('project') }/ProjectsPerCustomer?customerId=${ id }&customerIdentifier=${ identifier }`);
  }

  getAllRecordsFormPerProject(projectId: number, datasetId: number): Observable<any> {
    return this.http.get(`${ this.config.buildUri('form') }/RecordFormsPerProject?projectId=${ projectId }&datasetId=${ datasetId }`);
  }

  customerUsersPerAssignment(): Observable<any> {
    return this.http.get(`${ this.config.buildUri('assignment') }/CustomerUsersPerAssignment`);
  }

  recordFormsPerProjectAssignment(projectId: number, datasetId: number): Observable<Partial<FormModel>[]> {
    return this.http.get(`${ this.config.buildUri('form') }/FormsByProjectIdPerAssigment?projectId=${ projectId }&datasetId=${ datasetId }`);
  }

  projectsPerCustomerAssignment(id: number, identifier: string): Observable<Partial<ProjectModel>[]> {
    return this.http.get(`${ this.config.buildUri('project') }/ProjectsPerCustomerAssignment?customerId=${ id }&customerIdentifier=${ identifier }`);
  }

}
