import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';

import { select, Store } from '@ngrx/store';


import { MfnModalComponent } from './shared/mfn-modal/mfn-modal.component';
import { AppStateModel, getState } from './store/app-state.model';
import { AppAuthNService } from './user/app-auth-n.service';

import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { errorPopup, globalLoading, messagePopup } from './store/actions/ui.actions';
import { getUserBySub } from './user/store/user.actions';
import { toggleModal, updateModalData } from './form/store/form.actions';
import { EnvService } from './environment-integration/env.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'mfn-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  loading: Observable<boolean>;

  private subscription: Subscription = new Subscription();
  private isListFormPage: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    public store: Store<AppStateModel>,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private AppAuthService: AppAuthNService,
    private titleService: Title,
    private env: EnvService,
    private snackBar: MatSnackBar
  ) {
    this.subscription.add(
      this.router.events.subscribe((routeEv: NavigationEnd) => {
        if (routeEv instanceof NavigationEnd) {
          this.isListFormPage.next(routeEv.urlAfterRedirects === '/dashboard/form/list');
        }
      })
    );
    this.resetModal();

    this.loading = store.select(state => state.ui.loading);

    this.subscription.add(
      this.store.select(state => state.form.modal.toggle)
        .subscribe((toggle) => {
          if (toggle) {
            this.openDialog();
          }
        }));
  }

  ngOnInit(): void {
    if (window.location.host === this.env.domain) {
      window.location.href = `${ this.env.protocol }://navigatormf.${ this.env.mainDomain }`;
    }

    this.store.dispatch(globalLoading({ loading: false }));
    if (this.AppAuthService.isLoggedIn()) {
      const user = getState(this.store).user.profile;
      this.store.dispatch(getUserBySub({ profile: user }));
    }

    this.subscription.add(
      this.store.pipe(select(state => state.organization.current)).subscribe(organization => {
        if (organization.name) {
          this.titleService.setTitle(`Metaforms Navigator - ${ organization.name }`);
        }
      })
    );
    this.subscription.add(this.store.select(state => state.ui.error).subscribe(error => {
      if (error) {
        this.snackBar.open(error, '', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'snackbar--alert-background'
        });
        this.store.dispatch(errorPopup({ error: '' }));
      }
    }));

    this.subscription.add(this.store.select(state => state.ui.message).subscribe(message => {
      if (message) {
        this.snackBar.open(message, '', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'snackbar--message'
        });
        this.store.dispatch(messagePopup({ message: '' }));
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MfnModalComponent, {
      width: '500px'
    });

    this.subscription.add(dialogRef.afterClosed().subscribe(() => {
    }));
  }

  private resetModal(): void {
    this.store.dispatch(updateModalData('', ''));
    this.store.dispatch(toggleModal(false));
  }

}
