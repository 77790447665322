import { MfnHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationService {

    constructor(
        private config: AppConfig,
        private http: MfnHttp,
        private httpClient: HttpClient
    ) {
    }

    getUserOrganization(): Observable<any> {
        return this.http.get(this.config.buildUri('organization') + '/UserOrganization');
    }

    getAnonymousUserOrganization(anonymousUrl: string): Observable<any> {
        return this.httpClient.get(this.config.buildUri('organization') + '/AnonymousUserOrganization?url=' + anonymousUrl);
    }
}
