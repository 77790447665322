import { take } from 'rxjs/operators';
import { UiStateModel } from './models/ui-state.model';
import { appStateForm, FormStateModel } from '../form/store/form-state.model';
import { appStateUser, UserState } from '../user/store/user.state';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';

import { appStateRecord, RecordState } from '../record/store/record.state';
import { appStateOrganization, OrganizationState } from '../organization/store/organization.state';
import { projectState, ProjectStateModel } from './models/project.model';
import { appStateClient, ClientStateModel } from '../client/store/client-state.model';

/**
 * AppStateModel
 */
export interface AppStateModel {
  ui: UiStateModel;
  form: FormStateModel;
  user: UserState;
  record: RecordState;
  organization: OrganizationState;
  project: ProjectStateModel;
  client: ClientStateModel;
}

export const appState: AppStateModel = {
  ui: {
    loading: false,
    globalLoading: false,
    sidebar: 'open',
    header: true,
    footer: true,
    redirectUrl: '',
    error: '',
    message: ''
  },
  project: { ...projectState },
  ...appStateForm,
  ...appStateRecord,
  ...appStateUser,
  ...appStateOrganization,
  ...appStateClient
};


export const getState = (store: Store<AppStateModel>): AppStateModel => {
  let _state: AppStateModel;
  store.select(state => state).pipe(take(1)).subscribe(o => _state = o);
  return _state;
};


export const selectRecords = createFeatureSelector<AppStateModel, RecordState>('record');
export const selectRecordsList = createSelector(selectRecords, (state: RecordState) => state.list);
