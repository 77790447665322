import { Injectable } from '@angular/core';
import * as organizationActions from './organization.actions';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { OrganizationService } from '../organization.service';
import { OrganizationModel } from '../organization.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { updateCurrentOrganization } from './organization.actions';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions: Actions,
    private organizationService: OrganizationService
  ) {
  }

  getUserOrganization = createEffect(() =>
    this.actions
      .pipe(
        ofType(organizationActions.getUserOrganization),
        switchMap(() => {
          return this.organizationService.getUserOrganization().pipe(
            mergeMap((res) => {
              return [ updateCurrentOrganization({ organization: res }) ];
            }),
            catchError(err => {
              return [];
            }));
        })
      )
  );

  getAnonymousUserOrganization = createEffect(() =>
    this.actions.pipe(
      ofType(organizationActions.getAnonymousUserOrganization),
      switchMap(({ anonymousURL }) => {
        return this.organizationService.getAnonymousUserOrganization(anonymousURL).pipe(
          mergeMap((res: OrganizationModel) => {
            return [ updateCurrentOrganization({ organization: res }) ];
          }),
          catchError(err => {
            return [];
          }));
      })
    )
  );
}
