import { createAction, props } from '@ngrx/store';
import { UserModel } from '../user.model';
import { Profile } from 'oidc-client';

export enum ActionTypes {
  UPDATE_USER = '[User] Update user',
  UPDATE_USER_PROFILE = '[User] Update user profile',
  UPDATE_USER_FIELD = '[User] Update user field',
  UPLOAD_USER_AVATAR = '[User] Upload user avatar',
  RESET_USER = '[User] Reset user',
  GET_USER_BY_SUB = '[User] Get user by field',
  UPDATE_CURRENT_USER_CUSTOM_FIELD = '[User] Update user additional field'
}

export const getUserBySub = createAction(ActionTypes.GET_USER_BY_SUB, props<{ profile: Profile | UserModel }>());
export const resetUser = createAction(ActionTypes.RESET_USER);
export const updateUser = createAction(ActionTypes.UPDATE_USER, props<{ user: UserModel }>());
export const updateUserProfile = createAction(ActionTypes.UPDATE_USER_PROFILE, props<{ user: UserModel }>());
export const updateUserField = createAction(ActionTypes.UPDATE_USER_FIELD, props<{ field: string, value: string | number | any }>());
export const uploadUserAvatar = createAction(ActionTypes.UPLOAD_USER_AVATAR, props<{ formData: FormData }>());
export const updateCurrentUserCustomFields = createAction(ActionTypes.UPDATE_CURRENT_USER_CUSTOM_FIELD, props<{ field: string, value: string | number | any }>());
