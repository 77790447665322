import { createAction, props } from '@ngrx/store';
import { RecordModel } from '../record.model';
import { StatusesModel } from '../../shared/status-chips/statuses.model';

export enum ActionTypes {
    GET_RECORDS_BY_PROJECT_ID = '[Record] Get records by project id',
    GET_RECORDS_BY_PROJECTS_IDS = '[Record] Get records by projects ids',
    UPDATE_RECORD_LIST = '[Record] Update record list',
    RESET_RECORD_LIST = '[Record] Reset record list',
    UPDATE_RECORDS_COUNT = '[Record] Update records count',
    RESET_RECORDS_COUNT = '[Record] Reset records count',
    DELETE_RECORD = '[Record] Delete Record By ID',
    CREATE_RECORD = '[Record] Create record',
    SET_MSG_POPUP = '[Record] MSG_POPUP',
    SET_RECORD_ID = '[Record] SET_RECORD_ID',
    UPDATE_DATASET = '[Record] Update Dataset',
    SET_STATUSES = '[Record] SET_STATUSES',
    SET_CURRENT_RECORD = '[Record] Set current record',
    POPULATE_LIST_PAGE_TITLE = '[Record] Populate list page title',
    POPULATE_RECORD_PERMISSIONS = '[Record] Populate record permissions',
}

export const getRecordsByProjectId = createAction(ActionTypes.GET_RECORDS_BY_PROJECT_ID, props<{ projectId: number, assignmentId: number }>());

export const populateListPageTitle = createAction(ActionTypes.POPULATE_LIST_PAGE_TITLE, props<{ title: string }>());

export const getRecordsByProjectsIds = createAction(ActionTypes.GET_RECORDS_BY_PROJECTS_IDS, props<{ projectIds: number[] }>());

export const updateRecordList = createAction(ActionTypes.UPDATE_RECORD_LIST, props<{ records: RecordModel[] }>());

export const resetRecordList = createAction(ActionTypes.RESET_RECORD_LIST);

export const updateRecordsCount = createAction(ActionTypes.UPDATE_RECORDS_COUNT, props<{ count: any }>());

export const resetRecordsCount = createAction(ActionTypes.RESET_RECORDS_COUNT);

export const setRecordId = createAction(ActionTypes.SET_RECORD_ID, props<{ recordId: number }>());

export const updateDataset = createAction(ActionTypes.UPDATE_DATASET, props<{ dataset: any }>());


export const setStatuses = createAction(ActionTypes.SET_STATUSES, props<{ statuses: StatusesModel }>());

export const createRecord = createAction(ActionTypes.CREATE_RECORD, props<{ data: any, projectId: number, assignmentId: number }>());

export const deleteRecord = createAction(ActionTypes.DELETE_RECORD, props<{ recordId: number, projectId: number, assignmentId: number }>());


export const messagePopup = createAction(ActionTypes.SET_MSG_POPUP, props<{ message: string }>());

export const updateCurrentRecord = createAction(ActionTypes.SET_CURRENT_RECORD, props<{ record: RecordModel }>());

export const populateRecordPermissions = createAction(ActionTypes.POPULATE_RECORD_PERMISSIONS, props<{ permissions: any[] }>());
