import { FormModel } from '../form.model';


export interface ModalModel {
  toggle: boolean;
  title: string;
  description: string;
}

export interface FormStateModel {
  list: FormModel[];
  current: FormModel;
  modal: ModalModel;
}

export interface AppStateForm {
  form: FormStateModel;
}

export const appStateForm: AppStateForm = {
  form: {
    list: [],
    current: {
      id: null,
      projectId: null,
      assignmentId: null,
      organizationId: null,
      body: {
        pages: [],
        header: {},
        footer: {}
      },
      name: '',
      description: '',
      userIdCreated: null,
      userIdUpdated: null,
      shared: [],
      datasets: [],
      datasetId: null,
      questions: [],
      questionsPopulated: {},
      typeForm: 1,
      type: null,
      projectType: null,
      settings: {
        cardSettings: {
          cardBackgroundColor: '',
          cardButtonBackgroundColor: '',
          cardButtonForegroundColor: '',
          cardButtonOnHoverBackgroundColor: '',
          cardButtonOnHoverForegroundColor: '',
          cardForegroundPrimaryColor: '',
          cardForegroundSecondaryColor: '',
        }
      },
      createdAt: '',
      updatedAt: '',
    },
    modal: {
      toggle: false,
      title: '',
      description: ''
    }
  }
};
