import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { take, skip } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Card } from './card.model';
import { AppStateModel } from '../../store/app-state.model';
import { globalLoading } from '../../store/actions/ui.actions';
import { FormType } from '../../form/form.model';
import { StatusesModel } from '../status-chips/statuses.model';
import { setCurrentForm } from '../../form/store/form.actions';
import { getRecordsByProjectId } from '../../record/store/record.actions';

@Component({
  selector: 'mfn-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.scss' ]
})
export class MfmCardComponent implements OnInit, OnDestroy {

  @Input() card: Card;
  @Input() statuses: StatusesModel;
  @Input() cardType: string;
  @Input() width: number;
  @Input() height: number;
  @Output() afterViewClicked: EventEmitter<Card> = new EventEmitter();
  formType = FormType;
  cardTitleLength = 55;
  cardBtnStyles = {};
  cardStyle = {};
  cardPrimaryColor = {};
  cardSecondaryColor = {};
  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private store: Store<AppStateModel>
  ) {
  }

  ngOnInit(): void {
    this.cardTitleLength = this.width / 11;
    this.cardStyle['width'] = this.width + 'px';
    this.cardStyle['height'] = this.height + 'px';
    if (this.card.hasOwnProperty('settings') && this.card.settings !== null && this.card.settings.hasOwnProperty('cardSettings')) {
      this.cardBtnStyles['background-color'] = this.card.settings.cardSettings.cardButtonBackgroundColor;
      this.cardBtnStyles['color'] = this.card.settings.cardSettings.cardButtonForegroundColor;
      this.cardStyle['background-color'] = this.card.settings.cardSettings.cardBackgroundColor;
      this.cardPrimaryColor['color'] = this.card.settings.cardSettings.cardForegroundPrimaryColor;
      this.cardSecondaryColor['color'] = this.card.settings.cardSettings.cardForegroundSecondaryColor;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cardClick(card: Card): void {
    if (card.typeForm === FormType.Report || card.type === FormType.Report) {
      this.router.navigate([ '/dashboard/report/single/fill', card.assignmentId, 0 ], { relativeTo: this.activatedRoute });
    } else {
      this.store.dispatch(globalLoading({ loading: true }));
      if (this.cardType === 'recordForm') {
        this.afterViewClicked.emit(card);
      } else {
        this.subscription.add(
          this.store.select(state => state.record.list).pipe(skip(1), take(1)).subscribe(records => {
            let path = 'form';
            if (this.activatedRoute.snapshot.data.type === FormType.Survey) {
              path = 'survey';
            }
            if (records.length === 0) {
              this.store.dispatch(globalLoading({ loading: false }));
              this.router.navigate([ `/dashboard/${ path }/single/fill`, card.assignmentId, 0 ], { relativeTo: this.activatedRoute });
            } else {
              this.store.dispatch(setCurrentForm(card));
              this.store.dispatch(globalLoading({ loading: false }));
              this.router.navigate([ `/dashboard/${ path }/records/${ card.projectId }/${ card.assignmentId }` ], { relativeTo: this.activatedRoute });
            }
          })
        );
      }

      this.store.dispatch(getRecordsByProjectId({ projectId: card.projectId, assignmentId: card.assignmentId }));
    }
  }

  mouseOver($event: MouseEvent): void {
    if (this.card.hasOwnProperty('settings') && this.card.settings !== null && this.card.settings.hasOwnProperty('cardSettings')) {
      $event.target['style'].backgroundColor = this.card.settings.cardSettings.cardButtonOnHoverBackgroundColor;
      $event.target['style'].color = this.card.settings.cardSettings.cardButtonOnHoverForegroundColor;
    }
  }

  mouseLeave($event: MouseEvent): void {
    if (this.card.hasOwnProperty('settings') && this.card.settings !== null && this.card.settings.hasOwnProperty('cardSettings')) {
      $event.target['style'].backgroundColor = this.cardBtnStyles['background-color'];
      $event.target['style'].color = this.cardBtnStyles['color'];
    }
  }
}
